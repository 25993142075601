import React from 'react'
import styled from 'styled-components'
import ColorScheme from '../../ui/GlobalColors'
import Button from '../../ui/navigation/Buttons/Button'

export default function Hero({scrollTo}) {
    return (
        <HeroTextContainer>
            <h2>einfach lecker</h2>
            <h1>Paolo's Food</h1>
            <p>Ob Burger oder Pizza, wir liefern jetzt zu dir nach Hause.</p>
            <HeroButton primary onClick={() => scrollTo(1)}> jetzt bestellen</HeroButton>
        </HeroTextContainer>
    )
}

const HeroTextContainer = styled.div`
    padding: 5em;
    position: absolute;
    margin-left: 10vw;
    margin-top: 10vh;
    
    @media (min-width: 320px) and (max-width: 500px){
        margin: 5em 1.8em;
        padding: 0;
        font-size: .9rem;
    }
    @media (min-width: 414px) and (max-width: 767px){
        padding: 5em 1em;
        margin: 0;
        height: 100%;
        background: linear-gradient(to right, rgba(34,34,34,0) 50%, rgba(34,34,34, .5));
    }
    @media (min-width: 768px) and (max-width: 1024px){
        padding: 0;
        margin-left: 5em;
        margin-top: 10em;
    }

    h1 {
        padding: 0;
        margin: .2em 0em;
        font-size: 6.8em;
        text-transform: uppercase;
        @media (max-width: 415px){
            font-size: 4.8em;
        } 
    }
    h2 {
        font-size: 3em;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        color: ${ColorScheme.textLight};
        line-height: 0;
        @media (min-width: 320px) and (max-width: 415px){
            line-height: unset;
            font-size: 2em;
        }
    }
    p {
        max-width: 50%;
        padding: 0;
        margin: 1em 0em;
        font-size: 1.5em;
        line-height: 1.3em;
        @media (max-width: 415px) {
            max-width: 90%;
        }
    }
`
const HeroButton = styled(Button)`
    width: 50%;
    @media (max-width: 414px) {
        width: 90%;
    }
`