import React, { useState } from 'react'
import {Footerwrapper, Headline, FooterContainer, SocialMedia, FooterNav, BoundlessLink} from './FooterElements'
import {FaInstagram, FaFacebook, FaWhatsappSquare} from 'react-icons/fa'
import Impressum from '../../../sections/Impressum/Impressum';


export default function Footer() {
    const [imp, showImp] = useState(false);

    return (
        <>
        {imp ? <Impressum setImp={showImp}/> : 
        <Footerwrapper>
            <Headline>Paolo's Pizza &amp; Burger</Headline>
            
            <FooterContainer>
                <SocialMedia>
                    <p>Folge uns auf Social Media</p>
                    <ul>
                        <li><a href="https://www.instagram.com/paolos.burger/"><FaInstagram /></a></li>
                        <li><a href="https://www.facebook.com/pages/category/Food-Delivery-Service/Paolos-Burger-Pr%C3%BCm-171160554575463/"><FaFacebook /></a></li>
                        <li><a href="#"><FaWhatsappSquare /></a></li>
                    </ul>
                </SocialMedia>
            </FooterContainer>
            <FooterContainer>
                <FooterNav>
                    <ul>
                        <li><a href="#" onClick={() => showImp(true)}>Impressum &amp; Datenschutz</a></li>
                        <li><a href="mailto:paolospruem@web.de">Kontakt</a></li>
                        
                        <li><a href="https://paolos-pruem.de">Paolo's Pizza</a></li>
                        <li><a href="https://paolos-burger.de/">Paolo's Burger</a></li>
                    </ul>
                </FooterNav>
            </FooterContainer>
            <BoundlessLink >
                <p>created with ❤️ and 🔥 by <a href="https://boundless-perspective.com">boundless perspective</a></p>
            </BoundlessLink>
        </Footerwrapper>
        }
        </>
    )
}
