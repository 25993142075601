import React, { useEffect, useState } from 'react'
import Iframe from 'react-iframe';
import useMeasure from 'react-use-measure';
import {Container, Burger, Pizza, IframeContainer, Close, OrderNowBtn} from './OrderNewElements'

export default function OrderNew({scrollTo,lockScroll}) {
    const [open, setOpen] = useState(false);
    const [ContainerRef, ContainerBounds] = useMeasure();
    const [content, setContent] = useState(null);
    const urls = ["https://paolos-pruem.de/", "https://paolos-burger.de/"]

    const IframeOrder = (index) => {
        scrollTo(1);
        setOpen(true);
        setContent(index);
    }
    
    return (
        <>
        <Container ref={ContainerRef}>
            
        {open ? 
        <IframeContainer>
            <Iframe
            url={urls[content !== null ? content : 0]} 
            width={ContainerBounds.width}
            height={ContainerBounds.height}
            />
            <Close onClick={() => {setOpen(false); lockScroll(false)}}/>
        </IframeContainer>
        : 
        <>
        <Pizza>
            <OrderNowBtn onClick={() => {IframeOrder(0)}}>Pizza bestellen</OrderNowBtn>
        </Pizza>
        <Burger>
            <OrderNowBtn onClick={() => {IframeOrder(1)}}>Burger bestellen</OrderNowBtn>
        </Burger>    
        </>}
        </Container>
        
        </>
    )
}
