import React from 'react'
import Section from '../../ui/Blocks/Section'
import PizzaPiece from '../../../assets/jpg/pizza-piece.jpg'

export default function YourChoice() {
    return (
        <>
            <Section bg={PizzaPiece} style={{justifyContent: 'flex-end'}} ts>
                <h2>du hast</h2>
            </Section>
            <Section style={{justifyContent: 'flex-start', backgroundColor: 'rgba(34,34,34,.3)'}} ts>
                <h2>die wahl</h2>
            </Section>
        </>
    )
}
