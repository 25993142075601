import React, {  useState } from 'react'
import Navbar from '../navigation/Navbar/Navbar'


export default function Header({scrollTo}) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    
    return (
        <>
            <Navbar toggle={toggle} scrollTo={scrollTo} />            
        </>
    )
}
