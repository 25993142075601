import styled from 'styled-components'
import {NavLink as Link } from 'react-router-dom'
import {FaBars, FaArrowUp} from 'react-icons/fa'
import ColorScheme from '../../GlobalColors'


export const Nav = styled.nav`
    /* position: fixed; */
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    /* z-index: 998; */
`

export const NavLink = styled(Link)`
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    h1 {
        padding: 0;
        margin: 0;
    }
`
// export const Bars = styled(FaBars)`
//     display: none;
//     color: ${ColorScheme.linkColor};
//     z-index: 1;
//     @media screen and (max-width: 768px){
//         display: block;
//         position: absolute;
//         top: 0;
//         right: 0;
//         transform: translate(-100%, 75%);
//         font-size: 1.8rem;
//         cursor: pointer;
//     }
// `

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -24px;
    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavBtn = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
    @media screen and (max-width: 768px) {
        display: none;
    }
`
export const NavBtnLink = styled(Link)`
    border-radius: 4px;
    background: ${ColorScheme.btnBackgroundDefault};
    padding: 10px 22px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all .2s ease-in-out;
        color:${ColorScheme.btnTextPrimary};
        background-color: ${ColorScheme.btnBackgroundPrimary};
    }
`
export const TopArrow = styled(FaArrowUp)`
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 1em;
    margin-bottom: 1em;
    width: 25px;
    height: 25px;
    background-color: ${ColorScheme.btnBackgroundDefault};
    color: ${ColorScheme.btnTextDefault};
    border-radius: 50%;
    padding: .5em;
    opacity: .3;
    transition: .2s ease-in-out;
    z-index: 998;

    &:hover{
        opacity: 1;
        scale: 1.1;
        transition: .2s ease-in-out;
        
    }
    @media (max-width: 400px){
        position: fixed;
        left: 0;
        margin-left: 1em;
        bottom: 0;
        margin-bottom: 1em;
        opacity: 1;
        width: 25px;
        height: 25px;
    }
    @media (max-width: 768px){
        position: fixed;
        opacity: 1;
    }
`
