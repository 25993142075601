const darkred = "#3e1e15";
const meatred = '#7a3529';
const lightred = '#c05241';
const lightyellow = '#ffd399';
// const lightyellow = '#cb9d62';
const healthgreen = '#54b252';
const lightgreen = '#b4f8b1';
const darkgreen = '#1a230c';
const darkblue = '#11191F';
const darkbluedesat = '#1F2426';

// $color1: #1a230c;
// $color2: #54b252;
// $color3: #cb9d62;
// $color4: #c05241;
// $color5: #7a3529;
// $color6: #3e1e15;


const ColorScheme = {
    background: '#24110C',
    background2: '#24110C',
    backgroundDark: darkbluedesat,
    SubBackground: darkgreen,
    darkBorder: darkgreen,
    linkColor: lightgreen,
    linkActiveColor: lightyellow,
    headline: healthgreen,
    subheadline: darkblue,
    textLight: lightyellow,
    textDark: darkgreen,
    btnBackgroundPrimary: lightred,
    btnTextPrimary: lightyellow,
    btnBackgroundDefault: healthgreen,
    btnTextDefault: darkgreen,
}

export default ColorScheme;