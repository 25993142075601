import styled from 'styled-components'
import {ParallaxLayer as PL} from '@react-spring/parallax'

export const Container = styled.div`
    position: relative; 
    height: 100vh;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    perspective: 500px;
`
export const CustomPL = styled(PL)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    background-image: ${({bg}) => bg ? `url(${bg})` : "none"};
    background-size: cover;
    background-position: center;
    clip-path: ${({cp}) => cp ? cp : "none"};
`

export const ImgContainer = styled.div`
    grid-area: ${({gridArea})=> gridArea ? gridArea : "1 / 1" };
    background-image: ${({bg}) => bg ? `url(${bg})` : "none"};
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 414px) {
        background-position: ${({bgp}) => bgp? bgp : "center"};
    }
`