import React from 'react'
import styled from 'styled-components'

export default function Section({children, ...props}) {
    return (
        <>
            <SectionContainer {...props}>
                {children}
            </SectionContainer>
        </>
    )
}
 const SectionContainer = styled.section`
    background-image: ${({bg}) => bg ? `url(${bg})` : "none"};
    background-position: ${({bgp}) => bgp? bgp : "center"};
    text-shadow: ${({ts}) => ts? '7px 7px 24px rgba(34,34,34,.7)' : "none"};
    @media (min-width: 320px) and (max-width: 500px){
        h2 {
            font-size: 2rem;
        }
    }
`