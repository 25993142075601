import React from 'react'
import Section from '../../ui/Blocks/Section'
import Button from '../../ui/navigation/Buttons/Button'
import TeamImg from '../../../assets/jpg/team.jpg'
import styled from 'styled-components'
import ColorScheme from '../../ui/GlobalColors'

export default function Team() {
    return (
        <>
        <Container>
            <CustomSection bg={TeamImg}></CustomSection>
            <CustomSection style={{padding: "3em", alignItems:"center", justifyContent: "center", backgroundColor: `${ColorScheme.btnBackgroundPrimary}`}}>
                <div >
                <h2>Werde Teil des Teams</h2>
                <p>
                    Du bist ein Pizza Lover? Oder auch ein Burger Lover? 
                    <br />
                    Dann ist das schon der erste Schritt zu deiner Karriere bei Paolos. Du teilst die Leidenschaft unserer Gäste für unsere Produkte und bist hungrig auf Erfolg? Du arbeitest gerne im Team und bewältigst auch anspruchsvolle Situationen mit Begeisterung und positiver Energie? 
                    <br />
                    Dann bewirb dich jetzt!
                    <br />
                    <br />
                    <br />
                    Wir bieten dir.. 
                    <br />
                    .. einen sicheren Arbeitsplatz, mit spannenden, ständig wechselnden Aufgabenbereichen, in einer angenehmen Atmosphäre mit flexiblen Arbeitszeiten in einem engagierten Team. Um deinen Hunger auf Erfolg zu stillen, bieten wir dir attraktive Aufstiegsmöglichkeiten.
                </p>
                <Button><a href="mailto:paolospruem@web.de">Jetzt bewerben</a></Button>
                </div>
            </CustomSection> 
            </Container>
        </>
    )
}

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    @media (min-width: 320px) and (max-width: 500px){
        display: flex;
        flex-direction: column-reverse;
    }
`
const CustomSection = styled(Section)`
    @media (min-width: 320px) and (max-width: 500px) {
        min-height: 30vh;
        text-shadow: 3px 3px 3px rgba(34,34,34,0.3);
        h2 {
            font-size: 3em;
        }
        p {
            font-size: 1em;
        }
    }

`