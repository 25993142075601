import './../../ui/Stylesheets/helper.css'
import React from 'react'
import {CustomPL, ImgContainer} from './StartElements'
import BurgerFloat from '../../../assets/jpg/burger-float.jpg'
import OrderNew from '../../sections/Order/OrderNew'
import Hero from '../../sections/Hero/Hero'
import YourChoice from '../../sections/Filler/YourChoice'
import Team from '../../sections/Team/Team'
import { ParallaxLayer } from '@react-spring/parallax'



export default function Start({scrollTo, lockScroll}) {

    const layer1Poly = "circle(78.3% at 50% 93%)"

    return (
        <>
        <CustomPL speed={-0.6} offset={0}>
            <ImgContainer gridArea={"1 / 2"} bg={BurgerFloat} bgp={"30%"}/>
        </CustomPL>
        <CustomPL speed={0} offset={0}>
            <Hero scrollTo={scrollTo}/>
        </CustomPL>
        <CustomPL speed={0.6} offset={.99} factor={0.8} cp={layer1Poly}>
            <YourChoice />
        </CustomPL>
        <CustomPL speed={.5} offset={1} style={{display: "flex", width: "100%"}}>
            <OrderNew scrollTo={scrollTo} lockScroll={lockScroll}/>
        </CustomPL>
        <ParallaxLayer speed={.5} offset={1.99}>
            <div style={{width: "100%", height: "50%", backgroundColor: `#1F2426`}}></div>
        </ParallaxLayer>
        <ParallaxLayer speed={0.3} offset={2}>
            <Team />
        </ParallaxLayer>
        
        </>
    )
}
