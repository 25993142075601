import styled from 'styled-components'
import ColorScheme from '../../GlobalColors';

export const Footerwrapper = styled.div`
    background-color: ${ColorScheme.backgroundDark};
    position: relative; 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em;
    @media (min-width: 320px) and (max-width: 500px){
        padding: 0;
        h2 {
            font-size: 3em;
        }
    }
    
`
export const Headline = styled.h2 `
    position: relative;
    font-size: 5em;
    padding: 1rem;
    text-align: center;
    color: ${ColorScheme.headline};
    margin-bottom: .5em;

    &::after{
        content: "";
        position: absolute;
        width: 50%;
        height: 1px;
        background-color: rgba(80,80,80,1);
        left: 0;
        bottom: 0;
    }
`

export const FooterContainer = styled.div`
    position: relative;
    display: grid;
    place-content: center;
    width: 100%;
`
export const SocialMedia = styled.div`
    ul {
        font-size: 2.5em;
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color: whitesmoke;
        li {
            transition: .2s ease-in-out;
            &:hover{
                transition: .2s ease-in-out;
                transform: scale(1.2); 
            }
            a {
                color: ${ColorScheme.btnBackgroundPrimary};
                &:hover {
                    color: ${ColorScheme.linkColor};
                }
            }
        }
    }
    p {
        margin: 0;
        font-size: 1.8em;
        text-transform: lowercase;
        @media (min-width: 320px) and (max-width: 480px){
            margin-left: 1em;
        }
    }
    &::after{
        position: absolute;
        content: "";
        width: 50%;
        height: 1px;
        background-color: rgba(80,80,80,1);
        bottom: 0;
        right: 0;
    }
`

export const FooterNav = styled.div`
    padding: 5em 0em;
    ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        li {
            margin: 25px;

        }
    }
    @media (min-width: 320px) and (max-width: 500px){
        ul {
            flex-direction: column;
            li {
            margin: 15px;
            }
        } 
    }
`
export const BoundlessLink = styled.div`
    position: absolute;
    text-transform: lowercase;
    bottom: 0;
    left: 0;
    display: grid;
    place-content: center;
    width: 100%;
    p {
        color: #495154;
        a{
            color: #a1afb4;
        }
    }
    @media (min-width: 320px) and (max-width: 500px){
        position: relative;
    }

`