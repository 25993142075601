import React from 'react'
import styled from 'styled-components'
import ColorScheme from '../../GlobalColors'
import {animated as a} from '@react-spring/web'


const ButtonBody = styled.button`
    background: ${({primary}) => 
                    primary ?
                    ColorScheme.btnBackgroundPrimary : 
                    `${ColorScheme.btnBackgroundDefault}`
                };
    color: ${({primary}) => 
                    primary ?
                    ColorScheme.btnTextPrimary : 
                    ColorScheme.btnTextDefault
                };
    padding: .5em 2em;
    border: ${({primary}) => primary ? 
                        'none' : 
                        `.1em solid ${ColorScheme.btnTextDefault}`};
    border-radius: .2em;
    margin: 0;
    font-size: 1.2em;
    box-shadow:${({primary}) => 
                    primary ?
                    `0px 7px 24px -10px ${ColorScheme.btnBackgroundPrimary}66` :
                    `0px 7px 24px -10px ${ColorScheme.btnBackgroundDefault}66`  
                };
    &:hover{
        
    }
    a {
        text-decoration: none;
        color: inherit;
    }
`

const ButtonBodyAnimated = styled(a.button)`

`

export default function Button({animated, children, primary, ...props}) {
    return animated ?
     <ButtonBodyAnimated {...props} primary={primary?1:0}>
        {children}
    </ButtonBodyAnimated> : 
    <ButtonBody {...props} primary={primary?1:0}>
        {children}
    </ButtonBody>
}

