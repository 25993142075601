import React from 'react'
import styled from 'styled-components'
import Logo from '../../../../assets/png/Logo.png'
import {Nav, NavLink, NavMenu, NavBtnLink, NavBtn, TopArrow} from './NavbarElements'

export default function Navbar({toggle, scrollTo}) {
    return (
        <>
        <Nav>
            <NavLink to="/" onClick={() => scrollTo(0)}>
                <LogoContainer />
            </NavLink>
            <NavMenu>
                <NavLink to="/" onClick={() => scrollTo(0)} >Home</NavLink>
                <NavLink to="/" onClick={() => scrollTo(1)} >Auswahl</NavLink>
                <NavLink to="/" onClick={() => scrollTo(1.99)} >Jobs</NavLink>
            </NavMenu>
            <NavBtn>
                <NavBtnLink to="/"onClick={() => scrollTo(1)} >jetzt bestellen</NavBtnLink>
            </NavBtn>
        </Nav>
        {/* <TopArrow onMouseDown={() => scrollTo(0)}/> */}
        </>
    )
}

const LogoContainer = styled.div`
    background-image: url(${Logo});
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 200px;
    height: 100px;
    top: 0;
    left: 0;
    @media (min-width: 320px) and (max-width: 460px){
       position: absolute;
       width: 150px;
       height: 75px;
       top: 0;
       left: 50%;
       transform: translate(-50%);
    }
`