import {createGlobalStyle} from 'styled-components'
import ColorScheme from './GlobalColors'

const GlobalStyle = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;700;900&display=swap');

    html, body {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        font-family: 'Montserrat', sans-serif;
    }
    body {
        background-color: ${ColorScheme.backgroundDark};
    }
    h1 {
        color: ${ColorScheme.headline};
        
    }
    p {
        color: ${ColorScheme.textLight};
        
    }
    a {
        color: ${ColorScheme.linkColor};
        transition: .25s ease-in;
        &.active{
            transition: .25s ease-out;
            color: ${ColorScheme.linkActiveColor};
        }
        &:hover{
            letter-spacing: 1px;
        }
    }
    section {
        display: grid;
        place-content: center;
        background-size: cover;
        padding: 1em;
        color: whitesmoke;
        background-image: ${({bg}) => bg ? `url(${bg})` : "none"};
        background-position: ${({bgp}) => bgp? bgp : "center"};
        text-shadow: ${({ts}) => ts? '7px 7px 24px rgba(34,34,34,.7)' : "none"};
        
        h2 {
            font-size: 5em;
            padding: 0;
            margin: 0;
        }
        p {
            margin: 1.5em 0em;
            line-height: 1.5em;
        }
        @media (min-width: 320px) and (max-width: 413px){
            
        }
        @media (min-width: 414px) and (max-width: 767px){
            
        }
        @media (min-width: 768px) and (max-width: 1024px){

        }
    }
`

export default GlobalStyle;