import GlobalStyle from './components/ui/GlobalStyle'
import Header from './components/ui/navigation/Header'
import Start from './components/routes/Start/Start';
import Footer from './components/ui/navigation/Footer/Footer';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { useEffect, useState, useRef } from 'react';

function App() {
  const ParallaxRef = useRef();
  const [scrollOffset, setScrollOffset] = useState(0);
  const [scroll, setScroll] = useState(true);

  const scrollToOffset = (i) => {
    setScrollOffset(i);
    ParallaxRef.current.scrollTo(i);
  }

  useEffect(() => {
    ParallaxRef.current.scrollTo(scrollOffset);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollOffset, scroll, ParallaxRef.current])

  const lockScroll = (bool) => {
    setScroll(!bool);
  }
  return (
    <>
      <GlobalStyle />
      <Parallax id="prlx" pages={3.5} className="scrollBarHidden" ref={ParallaxRef} enabled={scroll}>
        
        <Start scrollTo={scrollToOffset} lockScroll={lockScroll}/>
        <ParallaxLayer speed={0} offset={3.5} sticky={{start: 3.5, end: 3.5}} factor={1}>
          <Footer />
        </ParallaxLayer>
        <ParallaxLayer style={{ height: "20px"}} speed={0} offset={0.0125}>
        {/* <ParallaxLayer sticky={{start: 0, end: 3}} style={{ height: "20px"}} > */}
          <Header scrollTo={scrollToOffset} />
        </ParallaxLayer>
      </Parallax>
    </>
  );
}

export default App;
