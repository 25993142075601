import styled from "styled-components";
import {animated as a} from '@react-spring/web'
import PizzaImg from '../../../assets/jpg/pizza.jpg'
import BurgerImg from '../../../assets/jpg/burger.jpg'
import {FaTimes} from 'react-icons/fa'
import ColorScheme from '../../ui/GlobalColors'

export const Container = styled(a.div)`
    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    @media (min-width: 320px) and (max-width: 460px){
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
    }
`

export const Pizza = styled(a.div)`
    display: grid;
    place-content: center;
    background-image: url(${PizzaImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`
export const Burger = styled(a.div)`
    display: grid;
    place-content: center;
    background-image: url(${BurgerImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`
export const IframeContainer = styled(a.div)`
    position: absolute;
    display: grid;
    place-content: center;
    width: 90vw;
    height: 90vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(34,34,34,0.9);
    z-index: 998;
`
export const Close = styled(FaTimes)`
    position: absolute;
    top: 5%;
    left: 5%;
    color: whitesmoke;
    font-size: 3rem;
    filter: drop-shadow(3px 3px 5px rgba(34,34,34,0.5));
    z-index: 999;
`
export const OrderNowBtn = styled.button`
    font-size: 2rem;
    background: none;
    border: 2px solid;
    border-radius: 5px;
    padding: .5em 1.5em;
    color: #222;
    transition: .2s ease-in-out;
    background-color: rgba(245,245,245, .4);
    box-shadow: 0px 0px 24px -8px rgba(245,245,245, 1);
    &:hover{
        color: whitesmoke;
        box-shadow: 0px 0px 24px 8px rgba(34,34,34, 1);
        transition: .2s ease-in-out;
        background-color: rgba(245,245,245, 0);
    }
    @media screen and (max-width: 400px){
        color: whitesmoke;
        text-shadow: 0px 0px 24px rgba(34,34,34, 1);
        background-color: rgba(245,245,245, .1)
    }
` 